import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { registerLicense } from '@syncfusion/ej2-base';
import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import App from './App';
import './i18n';
import { msalConfig } from './utils/ssoConfig';

// SyncFusion KEY
// registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Rd0diWX9ac3BQR2Be');
//react 18 key
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdEFiXXtZcXxXQGRf');

const msalInstance = new PublicClientApplication(msalConfig);

// Use createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ConfigProvider theme={{ token: { colorPrimary: '#4085c6' } }}>
        <App />
      </ConfigProvider>
    </MsalProvider>
  // </React.StrictMode>
);