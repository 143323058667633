import { BranchesOutlined, CheckCircleOutlined, CloudSyncOutlined, DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, FormOutlined, GlobalOutlined, LinkOutlined, MoreOutlined, OrderedListOutlined, PauseCircleOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Card, Col, Dropdown, Empty, List, Pagination, Row, Space, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { OriginInformationProgress } from '../../components/OriginInformationProgress';
import { ACTION, FEATURE } from '../../config/roles';
import { useAuth } from '../../hooks/auth';
import { Api_v2 } from '../../services/api';
import { WorkflowStatus } from '../../services/api/workflow';
import { TASK_STATUS } from '../FormBuilder/const';

function getWorkflowStatusTanslation() {
    for (const [key, val] of Object.entries(WorkflowStatus)) {
        if (val === status) return key;
    }
}

const handleDownload = async (correlationId) => {
    try {
        const response = await Api_v2.Workflow.PdfReport(correlationId);

        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

        const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
        const filename = `Workflow_${correlationId}_Report_${date}.pdf`;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Erro ao baixar o PDF', error);
    }
};

export const TaskHistoryTableBody = (props) => {
    const { listAnswersdata, setStoreFilters, handleEditSchedule, handleDeleteAnswer, handleReOpenAnswer, handleCloseTask, paginationAnswersConfig, onChangeAnswersTablePagination, tasksDone, tasksPending, tasksOpen, tasksLate, workflowRunning, showOriginInformationProgress, formId, handleStatus } = props;
    moment.locale('en');
    const { t } = useTranslation();
    const { can } = useAuth();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalProps, setModalProps] = useState();
    const [show, setShow] = useState(null);

    if (!listAnswersdata) return <></>;

    const answersTable = listAnswersdata.length == 0 ? <Empty /> : listAnswersdata.map((item, index) => {
        let color = 'rgb(199 200 202)';

        const answersStatus = TASK_STATUS[item.status];

        if (answersStatus === TASK_STATUS.Pending) {
            color = 'rgb(199 200 202)';
        } else if (answersStatus === TASK_STATUS.Open) {
            color = '#4085c6';
        } else if (answersStatus === TASK_STATUS.Done) {
            color = 'rgb(103 209 129)';
        } else if (answersStatus === TASK_STATUS.Late) {
            color = '#ff4d4f';
        }

        var view = item.status == 'Done' || (item.status == 'Approval' && item.taskTypeId != 'df3cb0ad-9e0a-44e1-898c-3e3a5df1483e');

        var items = [];

        if (can(FEATURE.SCHEDULE, [ACTION.EDIT]))
            items.push({
                label: <Link
                    to={{ pathname: `/formbuilder/task/log/${item.id}` }}
                >
                    {t('OpExportTask')}
                </Link>,
                icon: <FormOutlined />,
                key: 1
            });

        if (can(FEATURE.SCHEDULE, [ACTION.EDIT]) && item.status != 'Done')
            items.push({
                label: <Link
                    onClick={() => handleEditSchedule(item)}
                >
                    {t('Schedule.Edit.Schedule')}
                </Link >,
                icon: <FormOutlined />,
                key: 2
            });

        if (can(FEATURE.FORMTASK, [ACTION.CLOSETASK,]) && item.reOpen == 1 && item.status != 'Done')
            items.push({
                label: <Link
                    onClick={() => handleCloseTask(item)}
                >
                    {t('BtnCloseTask')}
                </Link >,
                icon: <GlobalOutlined />,
                key: 3
            });

        if (can(FEATURE.FORMTASK, [ACTION.DELETE]) && item.correlationId != null && item.correlationId != '')
            items.push({
                label: <Link
                    onClick={() => handleDeleteAnswer(item, true, false)}
                >
                    {t('BtnDeleteRestart')}
                </Link >,
                icon: <DeleteOutlined />,
                key: 4
            });

        if (can(FEATURE.FORMTASK, [ACTION.DELETE]) && item.correlationId != null && item.correlationId != '')
            items.push({
                label: <Link
                    onClick={() => handleDeleteAnswer(item, false, true)}
                >
                    {t('BtnDeleteAll')}
                </Link >,
                icon: <DeleteOutlined />,
                key: 5
            });

        if (can(FEATURE.FORMTASK, [ACTION.REOPEN]) && item.status == 'Done')
            items.push({
                label: <Link
                    onClick={() => handleReOpenAnswer(item)}
                >
                    {t('BtnReopen')}
                </Link >,
                icon: <GlobalOutlined />,
                key: 6
            });

        if (can(FEATURE.FORMTASK, [ACTION.DELETE]) && (item.correlationId == null || item.correlationId == ''))
            items.push({
                label: <Link
                    onClick={() => handleDeleteAnswer(item, false, false)}
                >
                    {t('BtnDelete')}
                </Link >,
                icon: <DeleteOutlined />,
                key: 7
            });

        if (can(FEATURE.FORMTASK, [ACTION.DELETE]) && (item.correlationId == null || item.correlationId == ''))
            items.push({
                label: <Link
                    onClick={() => {
                        const modalContent =
                            (
                                <List
                                    header={<Typography.Title level={4}>{item.title}</Typography.Title>}
                                    itemLayout="vertical"
                                    size="small"
                                    dataSource={item.correlatedWorkflowInstances}
                                    renderItem={(item) => {
                                        return (
                                            <List.Item>
                                                <Typography.Title level={5}                                                                                                >
                                                    {item.name}{' '}
                                                    <Link
                                                        onClick={setStoreFilters}
                                                        to={`/workflow/detail/${item.instanceId}`}
                                                        component={Typography.Link}
                                                    >
                                                        {' '}<LinkOutlined />{' '}
                                                    </Link>
                                                </Typography.Title>
                                                <Space direction="vertical">
                                                    <Typography.Text>
                                                        <Typography.Text strong                                                                                                        >
                                                            Created Date:{' '}
                                                        </Typography.Text>
                                                        {item.startedDate?.toLocaleString()}
                                                    </Typography.Text>

                                                    <Typography.Text>
                                                        <Typography.Text strong>
                                                            Status:{' '}
                                                        </Typography.Text>
                                                        {getWorkflowStatusTanslation(item.status)}
                                                    </Typography.Text>
                                                </Space>
                                            </List.Item>
                                        );
                                    }}
                                />
                            );

                        const modalProps = { content: modalContent };

                        setIsModalVisible(true);
                        setModalProps(modalProps);
                    }}
                >
                    {t('Workflow')}
                </Link >,
                icon: <FormOutlined />,
                key: 8
            });


        if (item.correlationId != null && item.correlationId != '')
            items.push({
                label: <Link
                    onClick={() => handleDownload(item.correlationId)}
                >
                    {t('BtnWorkflowReport')}
                </Link >,
                icon: <DownloadOutlined />,
                key: 9
            });

        return (
            <>
                <Tooltip placement="topLeft" title={view ? t('OpViewAnswer') : t('OpAnswerForm')}>
                    <Card className={index == 0 ? 'new-card-history card-p-10' : 'mt-10 new-card-history card-p-10'}>
                        <Row>
                            <Col span={item.statusWorkflow == 'Running' || item.statusWorkflow == 'DoneWorkflow' ? 1 : 0}>
                                <ZoomInOutlined
                                    onClick={() => { show == item.id ? setShow(null) : setShow(item.id); }}
                                    style={{ fontSize: 20, marginTop: 12, marginLeft: 5, }}
                                />
                            </Col>
                            <Col span={item.statusWorkflow == 'Running' || item.statusWorkflow == 'DoneWorkflow' ? 22 : 23}>
                                <Link
                                    onClick={setStoreFilters}
                                    to={{
                                        pathname: view ? `/formbuilder/view-answer/${item.id}` : `/formbuilder/apply/${item.formId}/${item.id}`,
                                        state: { redirectURL: '/home' },
                                        search: view ? `?lang=${item.languageId}` : '',
                                    }}
                                    key={item.id}
                                >
                                    <Row gutter={8} wrap={false}>
                                        <Col span={2}>
                                            <small>{t('Code')}</small>:{' '}
                                            <strong>{item.friendlyId}</strong>
                                            <br />
                                            {item.correlationId != null && item.correlationId != '' && (<><small>Workflow</small>:{' '}<strong>{item.correlationId}</strong></>)}
                                        </Col>
                                        <Col span={8} className="hidden-text-overflow">
                                            {t('ConfigPanel.Form.FormName')}:{' '}<strong style={{ color: '#000' }}>{item.title}</strong>
                                            <br />
                                            {t('Description')}:{' '}<strong style={{ color: '#2e2e2e' }}>{item.description}</strong>
                                        </Col>
                                        <Col span={5}>
                                            {t('Version')}:{' '}<strong style={{ color: '#000' }}>{item.formVersion}</strong>
                                            <br />
                                            {t('Location')}:{' '} <strong style={{ color: '#000' }}>{item.locationName}</strong>
                                            {item.formSequence && (
                                                <>
                                                    <br />
                                                    {t('FormNumber')}:{' '}
                                                    <strong style={{ color: '#000' }}>{item.formSequence}</strong>
                                                </>
                                            )}
                                        </Col>
                                        <Col span={4} className="hidden-text-overflow">
                                            {t('CreatedBy')}:{' '}<strong style={{ color: '#000' }}>{item.insertUserName}</strong>
                                            <br />
                                            {t('AssignedTo')}:{' '}<strong style={{ color: '#2e2e2e' }}>{item.userName}</strong>
                                        </Col>
                                        <Col span={3} className="hidden-text-overflow">
                                            {t('DueDate')}:
                                            <br />
                                            <strong style={{ color: '#000' }}>{moment(item.scheduleDate)?.format('YYYY-MMM-DD HH:mm')}</strong>
                                        </Col>
                                        <Col span={2}>
                                            <Tag color={color} className="tag-columns">{t(item.status)}</Tag>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>
                            <Col span={1}>
                                {can(FEATURE.FORMTASK, [ACTION.DELETE, ACTION.REOPEN, ACTION.EDIT, ACTION.CLOSETASK]) && (
                                    <Dropdown
                                        menu={{ items }}
                                        trigger={['click']}
                                        placement="bottomLeft"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <MoreOutlined
                                            className="more-outlined"
                                            style={{ float: 'right', paddingRight: 10 }}
                                        />
                                    </Dropdown>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Tooltip>

                {show == item.id && item.workflowTasks != null && item.workflowTasks.map((a) => {
                    return (
                        <Link
                            onClick={setStoreFilters}
                            to={{
                                pathname: `/formbuilder/view-answer/${a.id}`,
                                state: { redirectURL: '/home' },
                                search: `?lang=${a.languageId}`,
                            }}
                            key={a.id}
                            title={`Click to view answer`}
                        >
                            <Card className={'new-card-history card-p-10 card-ml-50 ' + (a.status == 'Done' ? 'new-card-history-workflow-done' : a.status == 'Late' ? 'new-card-history-workflow-late' : 'new-card-history-workflow-open')}                            >
                                <Row gutter={8} wrap={false}>
                                    <Col span={2}>
                                        <Tag className="tag-columns">
                                            {t(a.status)}
                                        </Tag>
                                    </Col>
                                    <Col span={2}>
                                        <small>{t('Code')}</small>:{' '}
                                        <strong>{a.friendlyId}</strong>
                                        <br />
                                        {a.correlationId != null && a.correlationId != '' && (
                                            <>
                                                <small>Workflow</small>:{' '}
                                                <strong>{a.correlationId}</strong>
                                            </>
                                        )}
                                    </Col>
                                    <Col span={9} className="hidden-text-overflow">
                                        {t('Name')}:{' '}<strong style={{ color: '#000' }}>{a.formName}</strong>
                                        <br />
                                        {t('Description')}:{' '}<strong style={{ color: '#2e2e2e' }}>{item.description}</strong>
                                    </Col>
                                    <Col span={4}>
                                        {t('Version')}:{' '}<strong style={{ color: '#000' }}>{a.version + 1}</strong>
                                        <br />
                                        {t('Location')}
                                        <br />
                                        <strong style={{ color: '#000' }}>{a.locationName}</strong>
                                    </Col>
                                    <Col span={4} className="hidden-text-overflow">
                                        {t('CreatedBy')}:{' '}<strong style={{ color: '#000' }}>{a.insertUserName}</strong>
                                        <br />
                                        {t('AssignedTo')}:{' '}<strong style={{ color: '#2e2e2e' }}>{a.userName}</strong>
                                    </Col>
                                    <Col span={3} className="hidden-text-overflow">
                                        {t('DueDate')}:
                                        <br />
                                        <strong style={{ color: '#000' }}>{moment(a.scheduleDate)?.format("YYYY-MMM-DD HH:mm")}</strong>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    );
                })}
            </>
        );
    });

    listAnswersdata?.length > 0 && answersTable.push(
        <Row key={999} gutter={[24, 24]} justify="end" style={{ padding: '10px' }}        >
            <Col>
                <Pagination
                    disabled={paginationAnswersConfig.total > 0 ? false : true}
                    showSizeChanger={false}
                    showQuickJumper={true}
                    current={paginationAnswersConfig.current}
                    onChange={onChangeAnswersTablePagination}
                    total={paginationAnswersConfig.total}
                />
            </Col>
        </Row>
    );

    return (
        <>
            <Row gutter={8} style={{ marginBottom: 10 }}>
                <Col span={4}>
                    <Card>
                        <Row justify="space-between" align="middle">
                            <Typography.Text>{t('History.WorkflowRunning')}</Typography.Text>
                            <BranchesOutlined style={{ color: "#4085c6", fontSize: 20 }} />
                        </Row>
                        <Typography.Title style={{ marginBottom: 0 }} level={2}>{workflowRunning}</Typography.Title>
                    </Card>
                </Col>
                <Col span={4}>
                    <Tooltip placement="topLeft" title={t('BtnFilter') + " " + t('History.PendingTasks')}>
                        <Card style={{ cursor: "pointer" }} onClick={() => handleStatus('Pending')}>
                            <Row justify="space-between" align="middle">
                                <Typography.Text>{t('History.PendingTasks')}</Typography.Text>
                                <PauseCircleOutlined style={{ color: "#4085c6", fontSize: 20 }} />
                            </Row>
                            <Typography.Title style={{ marginBottom: 0 }} level={2}>{tasksPending}</Typography.Title>
                        </Card>
                    </Tooltip>
                </Col>
                <Col span={4}>
                    <Tooltip placement="topLeft" title={t('BtnFilter') + " " + t('History.LateTasks')}>
                        <Card style={{ cursor: "pointer" }} onClick={() => handleStatus('Late')}>
                            <Row justify="space-between" align="middle">
                                <Typography.Text>{t('History.LateTasks')}</Typography.Text>
                                <ExclamationCircleOutlined style={{ color: "#ff4d4f", fontSize: 20 }} />
                            </Row>
                            <Typography.Title style={{ marginBottom: 0 }} level={2}>{tasksLate}</Typography.Title>
                        </Card>
                    </Tooltip>
                </Col>
                <Col span={4}>
                    <Tooltip placement="topLeft" title={t('BtnFilter') + " " + t('History.OpenTasks')}>
                        <Card style={{ cursor: "pointer" }} onClick={() => handleStatus('Open')}>
                            <Row justify="space-between" align="middle">
                                <Typography.Text>{t('History.OpenTasks')}</Typography.Text>
                                <CloudSyncOutlined style={{ color: "#999999", fontSize: 20 }} />
                            </Row>
                            <Typography.Title style={{ marginBottom: 0 }} level={2}>{tasksOpen}</Typography.Title>
                        </Card>
                    </Tooltip>
                </Col>
                <Col span={4}>
                    <Tooltip placement="topLeft" title={t('BtnFilter') + " " + t('History.DoneTasks')}>
                        <Card style={{ cursor: "pointer" }} onClick={() => handleStatus('Done')}>
                            <Row justify="space-between" align="middle">
                                <Typography.Text>{t('History.DoneTasks')}</Typography.Text>
                                <CheckCircleOutlined style={{ color: "#67D181", fontSize: 20 }} />
                            </Row>
                            <Typography.Title style={{ marginBottom: 0 }} level={2}>{tasksDone}</Typography.Title>
                        </Card>
                    </Tooltip>
                </Col>
                <Col span={4}>
                    <Card>
                        <Row justify="space-between" align="middle">
                            <Typography.Text>{t('TableTotal')}</Typography.Text>
                            <OrderedListOutlined style={{ color: "#999999", fontSize: 20 }} />
                        </Row>
                        <Typography.Title style={{ marginBottom: 0 }} level={2}>{tasksDone + tasksLate + tasksOpen + tasksPending}</Typography.Title>
                    </Card>
                </Col>
            </Row>
            {showOriginInformationProgress &&
                <Row>
                    <Col span={24}>
                        <Card style={{ marginBottom: 15 }}>
                            <OriginInformationProgress formId={formId} />
                        </Card>
                    </Col>
                </Row>
            }
            {answersTable}
            <Modal
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                okButtonProps={{ style: { display: 'none' } }}
            >
                {modalProps?.content}
            </Modal>
        </>
    );
};
