import { Col, Progress, Row, Spin, Tag, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';

export const OriginInformationProgress = ({ formId }) => {
    const [loading, setLoading] = useState(true);
    const [progressData, setProgressData] = useState(null);

    const getOriginInformationProgress = useCallback(() => {
        setLoading(true);
        Api.get(`/Form/getOriginInformationProgress?formId=${formId}`)
            .then(r => {
                // Supondo que o retorno seja um objeto único dentro de r.data.response
                if (r.data && r.data.response) {
                    // Se o retorno for um array com um único objeto, use o primeiro
                    const data = Array.isArray(r.data.response)
                        ? r.data.response[0]
                        : r.data.response;
                    setProgressData(data);
                }
            })
            .catch(error => {
                exceptionNotificationAPI(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [formId]);

    useEffect(() => {
        getOriginInformationProgress();
    }, [getOriginInformationProgress]);

    if (loading) {
        return <Spin size="large" />;
    }

    if (!progressData) {
        return null;
    }

    // Calcula a porcentagem baseada em ExecutedResponses e TotalExpectedResponses
    const percent = progressData.totalExpectedResponses > 0
        ? Math.round((progressData.executedResponses / progressData.totalExpectedResponses) * 100)
        : 0;

    return (
        <Row>
            <Col span={24}>
                <div
                    style={{
                        margin: 5,
                        background: "transparent",
                        padding: 5,
                        borderRadius: 4
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 5
                        }}
                    >
                        <Typography.Text style={{ fontSize: 14 }}>
                            {progressData.formName}
                        </Typography.Text>
                        <Tag style={{ fontSize: 14 }}>{`${progressData.executedResponses} / ${progressData.totalExpectedResponses}`}</Tag>
                    </div>
                    <Progress
                        percent={percent}
                        strokeColor="#6c757d"
                        size={["100%", 12.5]}
                    />
                </div>
            </Col>
        </Row>
    );
};
