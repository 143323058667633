import {
    FaPlay,
    FaPlayCircle,
    FaPodcast,
    FaMobileAlt,
    FaParagraph,
    FaRegBuilding,
    FaLaptop,
    FaRegListAlt,
    FaHome,
    FaTools,
    FaFlask,
    FaList,
    FaProjectDiagram,
    FaBlackTie,
    FaSitemap,
    FaUserAlt,
    FaStream,
    FaCog,
    FaUsersCog,
    FaUserCog,
    FaCogs,
    FaSlidersH,
    FaLanguage,
    FaFileAlt,
    FaPuzzlePiece,
    FaSearchLocation,
    FaRoad,
    FaFolderOpen,
    FaChartLine 
} from "react-icons/fa";

import {
    HomeOutlined,
    HomeFilled,
    FormOutlined,
    StockOutlined,
    CopyOutlined,
    PlusOutlined,
    BuildOutlined,
    AreaChartOutlined,
    UsergroupAddOutlined,
    FolderOutlined,
    QuestionCircleOutlined,
    BoxPlotOutlined,
    CompassOutlined,
    NodeIndexOutlined,
    FileOutlined,
    FileTextOutlined,
    PieChartOutlined
} from "@ant-design/icons";

import { CgListTree } from "react-icons/cg";
import { RiAdminLine , RiAccountPinCircleLine } from "react-icons/ri";


export const setIcon = (icon) => {
    switch (icon) {
        case 'FaHome': return <FaHome className="svg-icon" />
        case 'FaPlay': return <FaPlay className="svg-icon" />
        case 'FaFlask': return <FaFlask className="svg-icon" />
        case 'FaTools': return <FaTools className="svg-icon" />
        case 'FaProjectDiagram': return <FaProjectDiagram className="svg-icon" />
        case 'FaBlackTie': return <FaBlackTie className="svg-icon" />
        case 'FaList': return <FaList className="svg-icon" />
        case 'FaSitemap': return <FaSitemap className="svg-icon" />
        case 'FaUserAlt': return <FaUserAlt className="svg-icon" />
        case 'FaStream': return <FaStream className="svg-icon" />
        case 'FaCog': return <FaCog className="svg-icon" />
        case 'FaUsersCog': return <FaUsersCog className="svg-icon" />
        case 'FaCogs': return <FaCogs className="svg-icon" />
        case 'FaSlidersH': return <FaSlidersH className="svg-icon" />
        case 'FaLanguage': return <FaLanguage className="svg-icon" />
        case 'FaUserCog': return <FaUserCog className="svg-icon" />
        case 'FaPlayCircle': return <FaPlayCircle className="svg-icon" />
        case 'FaPodcast': return <FaPodcast className="svg-icon" />
        case 'FormOutlined': return <FormOutlined className="svg-icon" />
        case 'HomeOutlined': return <HomeOutlined className="svg-icon" />
        case 'HomeFilled': return <HomeFilled className="svg-icon" />
        case 'StockOutlined': return <StockOutlined className="svg-icon" />
        case 'FaMobileAlt': return <FaMobileAlt className="svg-icon" />
        case 'FaLaptop': return <FaLaptop className="svg-icon" />
        case 'CopyOutlined': return <CopyOutlined className="svg-icon" />
        case 'PlusOutlined': return <PlusOutlined className="svg-icon" />
        case 'FaParagraph': return <FaParagraph className="svg-icon" />
        case 'FaRegBuilding': return <FaRegBuilding className="svg-icon" />
        case 'FaRegListAlt': return <FaRegListAlt className="svg-icon" />
        case 'UsergroupAddOutlined': return <UsergroupAddOutlined className="svg-icon" />
        case 'FaSearchLocation': return <FaSearchLocation className="svg-icon" />
        case 'CgListTree': return <CgListTree className="svg-icon" />
        case 'OriginInfo': return <BoxPlotOutlined className="svg-icon" />
        case 'RiAdminLine': return <RiAdminLine className="svg-icon" />
        case 'MenuHelp': return <QuestionCircleOutlined className="svg-icon" />
        case 'AreaChartOutlined': return <AreaChartOutlined className="svg-icon" />
        case 'PieChartOutlined': return <PieChartOutlined className="svg-icon" />
        case 'FaChartLine': return <FaChartLine className="svg-icon" />
        case 'BuildOutlined': return <BuildOutlined className="svg-icon" />
        case 'faFileAlt': return <FaFileAlt className="svg-icon" />
        case 'FaPuzzlePiece': return <FaPuzzlePiece className="svg-icon" />
        case 'FolderOutlined': return <FolderOutlined className="svg-icon" />
        case 'FaRoad': return <FaRoad className="svg-icon" />
        case 'CompassOutlined': return <CompassOutlined className="svg-icon" />
        case 'NodeIndexOutlined': return <NodeIndexOutlined className="svg-icon" />
        case 'FileOutlined': return <FileOutlined className="svg-icon" />
        case 'FileTextOutlined': return <FileTextOutlined className="svg-icon" />
        case 'FaFolderOpen': return <FaFolderOpen className="svg-icon" />
        case 'RiAccountPinCircleLine': return <RiAccountPinCircleLine className="svg-icon" />
        default: return null
    }
};


