import { TreeSelectLocationBase } from '.';
import { useAuth } from '../../hooks/auth';

export function TreeSelectLocationCompany(props) {
    const { baseLocation, ...propsToPropagate } = props;

    const { user } = useAuth();

    return (
        <TreeSelectLocationBase
            baseLocation={baseLocation ?? user?.companyLocationId}
            {...propsToPropagate}
        />
    );
}
