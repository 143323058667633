import { ClockCircleOutlined, ExceptionOutlined, ExportOutlined, ReloadOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { Button, Card, Col, Divider, Empty, Input, Modal, Pagination, Popconfirm, Row, Select, Space, Switch, Tabs, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { error_message, success_message } from "../../components/Toastr";
import { TreeSelectLocationUser } from '../../components/TreeSelectLocation';
import { TreeSelectTopic } from '../../components/TreeSelectTopic';
import { UserPicker } from "../../components/UserPicker";
import { ACTION, FEATURE } from "../../config/roles";
import { useAuth } from '../../hooks/auth';
import Api, { Api_v2, exceptionNotificationAPI } from '../../services/api';
import useWindowDimensions from "../../services/useWindowDimensions";
import { truncateString } from "../../utils";
import ExportForm from './export';
import ScheduleForm from './form';
import { buildStatusAnswerList, parseFormListToTable, parseTaskListToTable } from './helper';
import { Container, ContentPanelIndex, FullCalendarContainer } from './styles';

import { useNavigate } from "react-router-dom";
import { TaskHistoryTableBody } from "./history";
import WorkflowTab from "./workflow";

const { TabPane } = Tabs;

const { Title, Text } = Typography;
const { Search } = Input;
const { Option, OptGroup } = Select;
const { confirm } = Modal;

function getScheduleDropdownStatusOptions() {
  return ['Late', 'Open', 'Pending', 'Recurrency', 'Approval'];
}

function getFormADHOCInitialState(filter) {
  return ({
    locationDirectParents: false,
    locationIncludeChildren: false,
    topicId: filter?.topicId,
    searchText: filter?.searchText ?? '',
    orderField: 'InsertDate',
    adhoc: true,
    validatePeriod: true,
    ascendingOrder: false,
    pageNumber: 1,
    pageSize: 10,
  });
}

function getHistoryInitialState(filter) {
  return ({
    isAdmin: false,
    reportToHistory: filter?.reportToHistory ?? 1,
    locationId: filter?.locationId,
    locationIncludeChildren: filter?.locationIncludeChildren ?? false,
    topicId: filter?.topicId,
    searchText: filter?.searchText,
    status: filter?.status,
    orderField: "InsertDate",
    ascendingOrder: false,
    pageNumber: 1,
    pageSize: 10,
    formId: null,
    formQuestionId: null,
    formQuestionValue: null,
  });
}

const HomeWrapper = () => {
  const [homeFilters, setHomeFilters] = React.useState(() => {
    return JSON.parse(sessionStorage.getItem("homeFilters"));
  });
  return (
    <HomeIndex
      filters={homeFilters}
    />
  )
};

const HomeIndex = (props) => {
  const { filters } = props;

  const historyTextSearchRef = React.useRef();
  const formAdhocSearchRef = React.useRef();

  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const calendarRef = React.createRef();
  const [showModalForm, setShowModalForm] = useState(false);

  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingTableForm, setLoadingTableForm] = useState(true);
  const [loadingTableTaskSchedule, setLoadingTableTaskSchedule] = useState(true);
  const [loadingTableApprovalTask, setLoadingTableApprovalTask] = useState(true);

  const [loadingTableAnswers, setLoadingTableAnswers] = useState(true);
  const [loadingTableFavorite, setLoadingTableFavorite] = useState(true);
  const [formTaskSelected, setFormTaskSelected] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);

  const [workflowRunning, setWorkflowRunning] = useState(0);
  const [tasksLate, setTasksLate] = useState(0);
  const [tasksOpen, setTasksOpen] = useState(0);
  const [tasksPending, setTasksPending] = useState(0);
  const [tasksDone, setTasksDone] = useState(0);

  const [filterNameSchedule, setFilterNameSchedule] = useState(filters?.searchText ?? "");
  const [searchForList, setSearchForList] = useState([]);
  const [filterStatusSchedule, setFilterStatusSchedule] = useState(filters?.status ?? getScheduleDropdownStatusOptions());

  const [showInfosScreen, setShowInfosScreen] = useState(filters?.tabActive ?? false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [tab, setTab] = useState(filters != null && filters.tabActive ? filters.tabActive : 1);
  const { user, can, } = useAuth();

  //Form states
  const [listFavoritedata, setListFavoritedata] = useState(null);
  const [filterFavoriteRequest, setFilterFavoriteRequest] = useState({
    locationId: '',
    topicId: '',
    searchText: '',
    status: 'Active',
    orderField: 'InsertDate',
    adhoc: true,
    validatePeriod: true,
    ascendingOrder: false,
    pageNumber: 1,
    pageSize: 999,
  });

  //Task states
  const [paginationTaskConfig, setPaginationTaskConfig] = useState({
    current: 1,
    total: 0,
  });

  //Answers states
  const [listAnswersdata, setListAnswersdata] = useState(null);
  const [filterAnswersRequest, setFilterAnswersRequest] = useState(getHistoryInitialState(filters));
  const [showOriginInformationProgress, setShowOriginInformationProgress] = useState(false);
  const [paginationAnswersConfig, setPaginationAnswersConfig] = useState({ current: 1, total: 0, });
  const [loadingIconAnswersFilter, setLoadingIconAnswersFilter] = useState(false);
  const [statusAnswersList, setStatusAnswersList] = useState();
  const [loadingIconAnswersStatusFilter, setLoadingIconAnswersStatusFilter,] = useState(false);

  //Form states
  const [listFormdata, setListFormdata] = useState(null);

  const [filterFormRequestLocationId, setFilterFormRequestLocationId] = useState();
  const [filterFormRequest, setFilterFormRequest] = useState(getFormADHOCInitialState(filters));
  const [paginationFormConfig, setPaginationFormConfig] = useState({ current: 1, total: 0, });
  const [loadingIconFormFilter, setLoadingIconFormFilter] = useState(false);

  //Task Schedule states
  const [listTaskScheduledata, setListTaskScheduledata] = useState(null);
  const [listTaskApprovalData, setListTaskApprovalData] = useState(null);
  const [filterTaskScheduleRequest, setFilterTaskScheduleRequest] = useState({
    userId: '',
    schedule: true,
    isAdmin: false,
    reportToHistory: filters?.reportToHistory ?? 1,
    withHistory: true,
    date: moment().startOf(),
    startDate: '',
    endDate: '',
    status: '',
    locationId: '',
    orderField: 'ScheduleDate',
    ascendingOrder: true,
    pageNumber: 1,
    pageSize: 999,
  }
  );

  const [listFormOptions, setListFormOptions] = useState(null);

  const [listFormQuestionOptions, setListFormQuestionsOptions] = useState(null);
  const [listFormQuestionLoading, setListFormQuestionsLoading] = useState(false);

  useEffect(() => {
    if (loadingTable) {
      //Answers
      getAnswersStatusData();
    }
  }, []);

  useEffect(() => {
    if ((user?.profiles != undefined || user?.profiles != null) && user?.profiles.filter(f => f.role.name === "Administrator").length == 0 && user?.customHomePage) {
      navigate("/home/custom");
    }
    setFilterAnswersRequest({
      ...filterAnswersRequest,
      isAdmin: (user?.profiles != undefined || user?.profiles != null) && user?.profiles.filter(f => f.role.name === "Administrator").length > 0
    });
    setFilterTaskScheduleRequest({ ...filterTaskScheduleRequest });
    if (!can(FEATURE.SCHEDULE, [ACTION.VIEW])) {
      handleChangeTab(2);
    }
  }, [user?.profiles])

  //Controla a exibição das informações da tabela, para a tag de pagination não carregue primeiro que as listas
  useEffect(() => {
    if (listTaskScheduledata != null) {
      setShowInfosScreen(true);
    }
  }, [listTaskScheduledata]);

  useEffect(() => {
    if (tab == 4) {
      setLoadingTableForm(true);
      getFormListData();
    }
  }, [filterFormRequest]);

  useEffect(() => {
    if (tab == 2) {
      setLoadingTableAnswers(true);
      getAnswersListData();
    }
  }, [filterAnswersRequest]);

  useEffect(() => {
    if (tab == 1) {
      setLoadingTableFavorite(true);
      getFavoriteListData();
    }
  }, [filterFavoriteRequest]);

  useEffect(() => {
    if (tab == 1 && user?.profiles != undefined) {
      getTaskScheduleListData();
    }
  }, [filterTaskScheduleRequest]);

  //set filters in sessionStorage
  function setStoreFilters() {
    let filters = {};
    if (tab == 1) { //schedule calendar 
      filters = {
        tabActive: tab,
        listStatus: filterStatusSchedule,
        searchText: filterNameSchedule,
        location: '',
        topic: '',
        status: filterStatusSchedule,
        searchTaskName: '',
        taskLocation: '',
        taskStatus: '',
        scheduleMonth: '',
        reportToHistory: filterTaskScheduleRequest.reportToHistory,
      }
    } else if (tab == 2) { //history
      filters = {
        tabActive: tab,
        searchText: filterAnswersRequest?.searchText,
        locationId: filterAnswersRequest?.locationId,
        locationIncludeChildren: filterAnswersRequest?.locationIncludeChildren,
        topicId: filterAnswersRequest?.topicId,
        status: filterAnswersRequest?.status,
        userId: filterAnswersRequest?.userId,
        created: filterAnswersRequest?.created,
        searchTaskName: '',
        taskLocation: '',
        taskStatus: '',
        scheduleMonth: '',
        reportToHistory: filterAnswersRequest?.reportToHistory,
      }
    } else if (tab == 4) { //adhoc forms / myActivities
      filters = {
        tabActive: tab,

        // ADHOC forms
        searchText: filterFormRequest.searchText,
        locationId: filterFormRequestLocationId,
        topicId: filterFormRequest.topicId,

        // Schedule
        scheduleMonth: '',
        showAllTasks: false,
      }
    }
    sessionStorage.setItem("homeFilters", JSON.stringify(filters));
  }

  const getFormListData = useCallback(() => {
    let locationToFilter;
    if (filterFormRequestLocationId) {
      locationToFilter = [filterFormRequestLocationId];
      filterFormRequest.locationDirectParents = false;
      filterFormRequest.locationIncludeChildren = false;
    }
    else {
      filterFormRequest.locationDirectParents = true;
      filterFormRequest.locationIncludeChildren = true;
      locationToFilter = [user?.locationId];
    }

    //locationIncludeChildren
    Api_v2.Form.getAdhocs(locationToFilter, filterFormRequest)
      .then(response => {
        if (response.data) {
          let formlist = parseFormListToTable(response.data);
          setListFormdata(formlist);

          let tempPaginationConfig = {
            ...paginationFormConfig,
            current: filterFormRequest.pageNumber,
            total: response.data.response.rowCount,
          };

          setLoadingTable(false);
          setLoadingTableForm(false);
          setLoadingIconFormFilter(false);
          setPaginationFormConfig(tempPaginationConfig);
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoadingTableForm(false);
      });
  });

  const getFavoriteListData = useCallback(() => {
    Api.post('/Form/getAllFavorite', filterFavoriteRequest)
      .then(response => {
        if (response.data) {
          let formlist = parseFormListToTable(response.data);
          setListFavoritedata(formlist);

          setLoadingTable(false);
          setLoadingTableFavorite(false);
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoadingTableFavorite(false);
      });
  });

  const getTaskScheduleListData = useCallback(() => {
    setLoadingTableTaskSchedule(true);
    var r = filterTaskScheduleRequest.reportToHistory;
    if (r != 1 && r != 2 && r != 3)
      filterTaskScheduleRequest.reportToHistory = 1;
    Api.post('/Task/getAll', filterTaskScheduleRequest)
      .then(response => {
        if (response.data) {
          let tasklist = parseTaskListToTable(response.data);
          setListTaskScheduledata(tasklist);

          setLoadingTable(false);
          setLoadingTableTaskSchedule(false);
        }
      })
      .catch(error => {
        let message = 'Failed to load the list!';
        if (/500/.test(error.message)) {
          message = t('Prompt.Webserver.Error');
        }
        setLoadingTableTaskSchedule(false);
        error_message(message);
      });

    Api.post('/FormTask/getAllApprovalTask', null)
      .then(response => {
        if (response.data) {
          let tasklist = parseTaskListToTable(response.data);
          setListTaskApprovalData(tasklist);

          setLoadingTableApprovalTask(false);
        }
      })
      .catch(error => {
        let message = 'Failed to load the list!';
        if (/500/.test(error.message)) {
          message = t('Prompt.Webserver.Error');
        }
        setLoadingTableApprovalTask(false);
        error_message(message);
      });
  });

  const handleFavoriteForm = (formClicked) => {
    var config = {
      title: formClicked.bookmark ? t('Favorite.Title.Unfavorite') : t('Favorite.Title.Favorite'),
      content: "Form: " + formClicked.title,
      okText: t('BtnYes'),
      onOk() {
        Api.post(`/Form/favorite?formId=${formClicked.id}`).then((response) => {
          getFormListData();
          success_message(t('Prompt.Save'));
        }).catch((error) => {
          exceptionNotificationAPI(error);
        });
      },
    }

    return confirm(config);
  }

  const getAnswersListData = useCallback(() => {
    setLoadingTableAnswers(true);
    setShowOriginInformationProgress(false);
    var r = filterAnswersRequest?.reportToHistory;
    if (r != 1 && r != 2 && r != 3)
      filterAnswersRequest.reportToHistory = 1;

    if (listFormOptions == null)
      Api.post('/Form/getAllSelectBase', null).then(r => {
        const formOptions = r.data.response.map(i => {
          return {
            label: i.name,
            value: i.baseId,
          }
        });
        setListFormOptions(formOptions);
      })
        .catch(error => {
          exceptionNotificationAPI(error);
          setLoadingTableAnswers(false);
        });

    Api.post('/FormTask/getAll', filterAnswersRequest)
      .then(response => {
        if (response.data) {
          let tasklist = parseTaskListToTable(response.data);
          setListAnswersdata(tasklist);

          setWorkflowRunning(response.data.response.workflowRunning);
          setTasksLate(response.data.response.tasksLate);
          setTasksOpen(response.data.response.tasksOpen);
          setTasksPending(response.data.response.tasksPending);
          setTasksDone(response.data.response.tasksDone);

          if (filterAnswersRequest?.formId) {
            let list = tasklist.filter((i) => i.hasOriginInformationQuestions);
            let showOrigin = list.length > 0;
            setShowOriginInformationProgress(showOrigin);
          }

          let tempPaginationConfig = {
            ...paginationTaskConfig,
            current: filterAnswersRequest?.pageNumber,
            total: response.data.response.rowCount,
          };

          setLoadingTable(false);
          setLoadingTableAnswers(false);
          setLoadingIconAnswersFilter(false);
          setLoadingIconAnswersStatusFilter(false);
          setPaginationAnswersConfig(tempPaginationConfig);
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoadingTableAnswers(false);
      });
  });

  const onChangeAnswersTablePagination = current => {
    let tempPaginationConfig = {
      ...paginationAnswersConfig,
      current: current,
    };

    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: current,
    };

    setPaginationAnswersConfig(tempPaginationConfig);
    setFilterAnswersRequest(tempFilterRequest);
  };

  const renderAdhocFormTable = () => {
    if (!listFormdata) return <></>;

    if (listFormdata.length == 0)
      return <Empty />

    let adhocFormTable = listFormdata.map((item, index) => {
      return (
        <Card key={item.id} className={index == 0 ? "card-p-10" : "mt-10 card-p-10"}>
          <Row>
            <Col span={23}>
              <Link
                onClick={setStoreFilters}
                to={{
                  pathname: `/formbuilder/apply/${item.id}`,
                  state: { redirectURL: "/home" }
                }}
                key={item.id}
                title={"Click to answer form - " + item.title}
              >
                <Row>
                  <Col className="hidden-text-overflow" xl={21} lg={21}>
                    <span style={{ color: "#000" }}>{item.title}</span>
                  </Col>
                  <Col xl={3} lg={3}>
                    <small style={{ color: "#000" }}>{item.created_at}</small>
                  </Col>
                </Row>
              </Link>
            </Col>
            <Col span={1}>
              {item.bookmark && (<StarFilled style={{ fontSize: 16, color: "#f9d71c" }} onClick={() => handleFavoriteForm(item)} title="Unfavorite" twoToneColor="#f9d71c" />)}
              {!item.bookmark && (<StarTwoTone style={{ fontSize: 16 }} onClick={() => handleFavoriteForm(item)} title="Favorite" twoToneColor="#f9d71c" />)}
            </Col>
          </Row>
        </Card>
      );
    });

    adhocFormTable.push(
      <Row key={"row_pagination"} gutter={[24, 24]} justify="end" style={{ padding: "10px" }}>
        <Col>
          <Pagination
            disabled={paginationFormConfig.total > 0 ? false : true}
            showSizeChanger={false}
            current={paginationFormConfig.current}
            onChange={onChangeFormTablePagination}
            total={paginationFormConfig.total}
          />
        </Col>
      </Row>,
    );

    return adhocFormTable;
  };

  const onChangeFormTablePagination = current => {
    let tempPaginationConfig = {
      ...paginationFormConfig,
      current: current,
    };

    let tempFilterRequest = {
      ...filterFormRequest,
      pageNumber: current,
    };

    setPaginationFormConfig(tempPaginationConfig);
    setFilterFormRequest(tempFilterRequest);
  };

  const renderFavoriteTable = () => {
    if (!listFavoritedata) return <></>;

    if (listFavoritedata.length == 0)
      return <Empty />

    var table = listFavoritedata.filter(x => x.adhoc).map((item, index) => {
      return <>
        <Link
          to={{
            pathname: `/formbuilder/apply/${item.id}`,
            state: { redirectURL: "/home" }
          }}
          key={item.id}
          style={{ color: '#000' }}
          title={"Click to answer form - " + item.title}
        >
          <Row gutter={8} wrap={false}>
            <Col span={1}>
              <StarFilled style={{ fontSize: 16, color: "#f9d71c" }} twoToneColor="#f9d71c" />
            </Col>
            <Col span={23}>
              <Row gutter={8}>
                <Col span={24}>
                  {item.title}
                </Col>
                <Col span={24}>
                  <Text type="secondary">{`${t('Location')}: ${item.location.locationPath?.join(" | ") ?? item.location.name}`}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Link>
        {index + 1 < listFavoritedata.filter(x => x.adhoc).length && <Divider />}
      </>;
    });

    return table;
  };

  const renderApprovalTable = () => {
    if (!listTaskApprovalData) return <></>;

    if (listTaskApprovalData.length == 0)
      return <Empty />

    var table = listTaskApprovalData.map((item, index) => {
      let languageId = "";
      if (item.languageId)
        languageId = `?lang=${item.languageId}`;

      return <>
        <Link
          to={{
            pathname: `/formbuilder/view-answer/${item.taskId + languageId}`,
            state: { redirectURL: "/home" }
          }}
          key={item.id}
          style={{ color: '#000' }}
          title={t("OpViewAnswer")}
        >
          <Row gutter={8} wrap={false}>
            <Col span={1}>
              <ExceptionOutlined style={{ fontSize: 16, color: "#4085c6" }} />
            </Col>
            <Col span={23}>
              <Row gutter={8}>
                <Col span={24}>
                  {item.title}
                </Col>
                <Col span={24}>
                  <Text type="secondary">{`${t('Code')}: ${item.friendlyId}`}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Link>
        {index + 1 < listTaskApprovalData.length && <Divider />}
      </>;
    });

    return table;
  };

  const handleAnswersSearchFilter = value => {
    value ? setLoadingIconAnswersFilter(true) : setLoadingIconAnswersFilter(false);

    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      searchText: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const handleAnswersSearchTopic = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      topicId: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const handleAnswersSearchUser = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      userId: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const handleAnswersSearchCreatedUser = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      created: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const handleAnswersSearchForm = (value, option) => {
    const label = option?.label || value;

    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      formId: value,
      formQuestionId: null,
      formQuestionValue: null
    };

    getQuestionsFromForm(value);

    setSelectedValue({ label, value });

    setFilterAnswersRequest(tempFilterRequest);

    if (!value || option.label == '' || option.label == null) {
      setSelectedValue({ label: '', value: '' });
    }
  };

  const handleAnswersSearchFormQuestion = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      formQuestionId: value,
      formQuestionValue: null
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const handleAnswersSearchFormQuestionValue = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      formQuestionValue: value
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const getQuestionsFromForm = async (formId) => {
    setListFormQuestionsLoading(true);
    Api.get('/Form/getAllQuestionSelect?formId=' + formId).then(r => {
      const formQuestionsOptions = r.data.response.map(i => {
        return {
          label: i.name,
          value: i.trackingId,
        }
      });

      setListFormQuestionsOptions(formQuestionsOptions);
      setListFormQuestionsLoading(false);
    })
      .catch(error => {
        exceptionNotificationAPI(error);
        setListFormQuestionsLoading(false);
      });
  }

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const filterOption = (input, option) => {
    const label = option.label || '';
    return label.toLowerCase().includes(input.toLowerCase());
  };

  const onSearch = (value) => {
    setSearchValue(value);
    const filtered = listFormOptions.filter(option => (option.label || '').toLowerCase().includes(value.toLowerCase()));
    setFilteredOptions(filtered);
  };

  const [selectedValue, setSelectedValue] = useState({ label: null, value: '' });

  const handleClear = () => {
    setFilteredOptions([]);
    setSearchValue(null);
    setSelectedValue({ label: null, value: null });
  };

  const renderAnswersFormDropdown = () => {
    return (
      <>
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          placeholder={t("History.Form")}
          onSearch={onSearch}
          className="space-search"
          options={filteredOptions.length > 0 ? filteredOptions : listFormOptions}
          filterOption={filterOption}
          onChange={handleAnswersSearchForm}
          onClear={handleClear}
          value={selectedValue.label || (!filteredOptions.length && !searchValue ? t("History.Form") : null)}
          disabled={loadingTableAnswers}
        />

        {filterAnswersRequest?.formId != null &&
          <>
            <Select
              style={{ marginTop: 10 }}
              allowClear
              loading={listFormQuestionLoading}
              placeholder={t("History.FormQuestion")}
              className="space-search"
              options={listFormQuestionOptions}
              onChange={handleAnswersSearchFormQuestion}
              value={filterAnswersRequest?.formQuestionId}
            />
            {filterAnswersRequest?.formQuestionId != null &&
              <Input.Search
                style={{ marginTop: 10 }}
                allowClear
                loading={listFormQuestionLoading}
                placeholder={t("History.FormQuestionValue")}
                className="space-search"
                onSearch={handleAnswersSearchFormQuestionValue}
                defaultValue={filterAnswersRequest?.formQuestionValue}
              />
            }
          </>
        }
      </>
    );
  }

  const renderAnswersTopicDropdown = () => {
    return (
      <TreeSelectTopic
        placeholder={t('Topic')}
        className="space-search"
        onChange={handleAnswersSearchTopic}
        value={filterAnswersRequest?.topicId}
        disabled={loadingTableAnswers}
      ></TreeSelectTopic>
    );
  };

  const renderAnswersLocationDropdown = () => {
    return (
      <TreeSelectLocationUser
        placeholder={t('Location')}
        includeDirectParents={false}
        className="space-search"
        onChange={handleAnswersSearchLocation}
        value={filterAnswersRequest?.locationId}
        disabled={loadingTableAnswers}
      ></TreeSelectLocationUser>
    );
  };

  const renderAnswersLocationBelowDropdown = () => {
    if (filterAnswersRequest?.locationId != undefined && filterAnswersRequest?.locationId != "")
      return (
        <><Switch disabled={loadingTableAnswers} style={{ marginTop: 10 }} value={filterAnswersRequest?.locationIncludeChildren} onChange={handleAnswersSearchLocation2} /> {t('History.LocationBelow')}</>
      );
    else return <></>
  };

  const handleAnswersSearchLocation2 = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      locationIncludeChildren: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const handleAnswersSearchLocation = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      locationId: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const getAnswersStatusData = useCallback(() => {
    let tempStatusList = buildStatusAnswerList();

    setStatusAnswersList(tempStatusList);
  });

  const renderAnswersStatusDropdown = () => {
    if (statusAnswersList == undefined)
      return (<></>);

    let options = statusAnswersList.length > 0
      && statusAnswersList.map((item) => {
        return (
          <Option key={item.id} value={item.id}>{t(item.name)}</Option>
        );
      });

    return (
      <Select
        allowClear
        loading={loadingIconAnswersStatusFilter}
        value={filterAnswersRequest?.status}
        placeholder={t('Status')}
        className="space-search"
        onChange={handleAnswersSearchStatus}
        disabled={loadingTableAnswers}
      >
        <OptGroup label="Tasks">
          {options}
        </OptGroup>
        <OptGroup label="Workflow">
          <Option key={"Running"} value={"Running"}>{t("History.WorkflowRunning")}</Option>
          <Option key={"DoneWorkflow"} value={"DoneWorkflow"}>{t("History.WorkflowDone")}</Option>
        </OptGroup>
      </Select>
    );
  };

  const renderAnswersReportDropdown = () => {
    return (
      <Select
        value={filterAnswersRequest?.reportToHistory}
        placeholder={t('History.Tasks')}
        className="space-search"
        onChange={handleAnswersReport}
        disabled={loadingTableAnswers}
      >
        <Option value={1}>{t('History.MyTasks')}</Option>
        {user?.showOnlyMyTasks !== true ? <Option value={2}>{t('History.LocationTasks')}</Option> : null}
        {user?.showOnlyMyTasks !== true ? <Option value={3}>{t('History.ReportTasks')}</Option> : null}
      </Select>
    );
  };

  const handleAnswersReport = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      reportToHistory: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
  };

  const handleFormSearchFilter = value => {
    value ? setLoadingIconFormFilter(true) : setLoadingIconFormFilter(false);

    let tempFilterRequest = {
      ...filterFormRequest,
      pageNumber: 1,
      searchText: value,
    };

    setFilterFormRequest(tempFilterRequest);
  };

  const handleFormSearchTopic = value => {
    let tempFilterRequest = {
      ...filterFormRequest,
      pageNumber: 1,
      topicId: value,
    };

    setFilterFormRequest(tempFilterRequest);
  };

  const renderFormLocationDropdown = () => {
    return (
      <TreeSelectLocationUser
        placeholder={t('Location')}
        className="space-search"
        onChange={handleFormSearchLocation}
      />
    );
  };

  const handleFormSearchLocation = value => {
    let tempFilterRequest = {
      ...filterFormRequest,
      pageNumber: 1,
    };

    setFilterFormRequest(tempFilterRequest);
    setFilterFormRequestLocationId(value);
  };

  const renderFormTopicDropdown = () => {
    return (
      <TreeSelectTopic
        placeholder={t('Topic.Title')}
        className="space-search"
        value={filterFormRequest.topicId}
        onChange={handleFormSearchTopic}
      />
    );
  };

  const handleAnswersSearchStatus = value => {
    let tempFilterRequest = {
      ...filterAnswersRequest,
      pageNumber: 1,
      status: value,
    };

    setFilterAnswersRequest(tempFilterRequest);
    setLoadingIconAnswersStatusFilter(true);
  };

  const handleDeleteAnswer = (value, restart, all) => {
    var content = "";
    if (restart && !all)
      content = t('Delete.Content2');
    else if (!restart && all)
      content = t('Delete.Content3');
    else
      content = t('Delete.Content1');

    var config = {
      title: value.title,
      content: content,
      okText: t('BtnYes'),
      onOk() {
        Api.post(`/FormTask/delete?id=${value.id}&restart=${restart}&all=${all}`).then((response) => {
          setLoadingTableAnswers(true);
          getAnswersListData();
          success_message(t('Prompt.Delete'));
        }).catch((error) => {
          let message = "Failed to delete the record!";
          if (/500/.test(error.message)) {
            message = t('Prompt.Webserver.Error');
          }
          error_message(message);
        });
      },
    }

    return confirm(config);
  }

  const handleEditSchedule = (formTask) => {
    let formTaskObj = {
      id: formTask.id,
      disableFormOption: true,
      extendedProps: { //pattern that fullcalendar use
        ...formTask,
        scheduleDate: formTask.scheduleDate,
        userId: formTask.userId,
        formId: formTask.formId,
        status: Array.isArray(formTask.status) ? formTask.status[0] : formTask.status,
        locationId: formTask.locationId,
        history: [],
        option: "notrepeat"
      }
    };
    setFormTaskSelected(formTaskObj);
    setShowModalForm(true);
  }

  const handleReOpenAnswer = value => {
    var config = {
      title: t('ReOpen.Title'),
      content: "Answer: " + value.title,
      okText: t('BtnYes'),
      onOk() {
        Api.post(`/FormTask/reopen?id=${value.id}`).then((response) => {
          setLoadingTableAnswers(true);
          getAnswersListData();
          success_message("Record reopen successfully!");
        }).catch((error) => {
          let message = "Failed to reopen the record!";
          if (/500/.test(error.message)) {
            message = t('Prompt.Webserver.Error');
          }
          error_message(message);
        });
      },
    }

    return confirm(config);
  }

  const handleCloseTask = value => {
    var config = {
      title: t('CloseTask.Title'),
      content: "Answer: " + value.title,
      okText: t('BtnYes'),
      onOk() {
        Api.post(`/FormTask/close?id=${value.id}`).then((response) => {
          setLoadingTableAnswers(true);
          getAnswersListData();
          success_message("Close Task successfully!");
        }).catch((error) => {
          let message = "Failed to close the task!";
          if (/500/.test(error.message)) {
            message = t('Prompt.Webserver.Error');
          }
          error_message(message);
        });
      },
    }

    return confirm(config);
  }

  const renderAnswersTable = () => {
    return (
      <TaskHistoryTableBody
        handleCloseTask={handleCloseTask}
        handleStatus={handleAnswersSearchStatus}
        handleDeleteAnswer={handleDeleteAnswer}
        handleEditSchedule={handleEditSchedule}
        handleReOpenAnswer={handleReOpenAnswer}
        listAnswersdata={listAnswersdata}
        onChangeAnswersTablePagination={onChangeAnswersTablePagination}
        paginationAnswersConfig={paginationAnswersConfig}
        setStoreFilters={setStoreFilters}
        tasksDone={tasksDone}
        tasksOpen={tasksOpen}
        tasksLate={tasksLate}
        tasksPending={tasksPending}
        workflowRunning={workflowRunning}
        showOriginInformationProgress={showOriginInformationProgress}
        formId={filterAnswersRequest?.formId}
      />
    )
  };

  const renderScheduleStatusDropdown = () => {
    if (statusAnswersList == undefined)
      return (<></>);

    let options = statusAnswersList.length > 0
      && statusAnswersList.map((item) => {
        return (
          <Option key={item.id} value={item.id}>{t(item.name)}</Option>
        );
      });

    return (
      <Select
        maxTagCount="responsive"
        allowClear
        mode="multiple"
        placeholder="Status"
        value={filterStatusSchedule}
        defaultValue={getScheduleDropdownStatusOptions()}
        onChange={(e) => {
          setFilterStatusSchedule(e);
        }}
        style={{ width: '100%', marginTop: 5 }}
        disabled={loadingTableAnswers}
      >
        {options}
      </Select>
    );
  };

  const renderSchedule = () => {
    return <Card style={{ visibility: loadingTableTaskSchedule ? 'hidden' : 'visible' }} >
      <FullCalendarContainer className="font-face-gbold">
        <FullCalendar
          timeZone='UTC'
          height={height - 230}
          expandRows={true}
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            start: can(FEATURE.SCHEDULE, [ACTION.CREATE]) ? 'new myTasks' : 'myTasks',
            center: 'title',
            end: 'prev,next'
          }}
          buttonText={
            {
              month: t('Schedule.Month'),
              list: t('List.Title')
            }
          }
          defaultAllDay={true}
          events={listTaskScheduledata != null && listTaskScheduledata.filter(x => (x.title.toLowerCase().indexOf(filterNameSchedule.toLowerCase()) > -1 || x.friendlyId.indexOf(filterNameSchedule.toLowerCase()) > -1) && filterStatusSchedule.includes(x.status))}
          eventContent={(e) => {
            let status = e.event.extendedProps.status;

            let actionEdit = null;
            if (can(FEATURE.SCHEDULE, [ACTION.EDIT])) {
              actionEdit = "Edit";
            }

            let languageId = "";
            if (e.event.extendedProps.languageId) {
              languageId = `?lang=${e.event.extendedProps.languageId}`;
            }

            let hasFriendlyId = (e.event.extendedProps.friendlyId != null)
              ? e.event.extendedProps.friendlyId
              : '';

            let isRecurrency = e.event.extendedProps.formScheduleRecurrencyId != null && e.event.extendedProps.formScheduleRecurrencyId != "";

            var documentControl = e.event.extendedProps.taskTypeId == "829855ba-6a28-4013-8440-20ff97078389" || e.event.extendedProps.taskTypeId == "b2b427ee-5199-4459-83fd-d28b3d2c18d2";
            var documentControl1 = e.event.extendedProps.taskTypeId == "829855ba-6a28-4013-8440-20ff97078389";
            var documentControl2 = e.event.extendedProps.taskTypeId == "b2b427ee-5199-4459-83fd-d28b3d2c18d2";

            var taskControl = e.event.extendedProps.taskTypeId == "df3cb0ad-9e0a-44e1-898c-3e3a5df1483e";

            var approvalControl = e.event.extendedProps.taskTypeId == "df3cb0ad-9e0a-44e1-898c-3e3a5df1483e";
            var formTask = e.event.extendedProps.taskTypeId == "ad89b18a-802e-4a51-94ae-bf73e7329a68";
            return <Popconfirm
              title={<><b>Select an option</b> <br />
                {(hasFriendlyId && !isRecurrency) && <>{"Task ID: " + hasFriendlyId}<br /></>}
                <span title={e.event.title}>{"Task: " + truncateString(e.event.title, 50)}</span><br />
                {"User: " + e.event.extendedProps.userName}<br />
                {(hasFriendlyId && !isRecurrency) && <>{"Created at: " + moment(e.event.extendedProps.insertDate).format("YYYY-MMM-DD HH:mm:ss")}<br /></>}
                {documentControl1 && <><b>Document Task</b></>}
                {documentControl2 && <><b>Document Revision</b></>}
                {taskControl && <><b>Form Task</b></>}
                {approvalControl && <><b>Approval Task</b></>}
              </>}
              okText={documentControl ? t('OpViewDocument') : status == "Done" ? t('OpViewAnswer') : t('OpAnswerForm')}
              onConfirm={() => {
                setStoreFilters(); //set actual filters im sessionStorage

                navigate(taskControl ? "/formbuilder/view-answer/" + e.event.extendedProps.taskId + languageId
                  : documentControl ? "/documentcontrol/details/" + e.event.extendedProps.documentId
                    : status == "Done" || status == "Approval" ? "/formbuilder/view-answer/" + e.event.id + languageId
                      : e.event.extendedProps.description == "Fake"
                        ? "/formbuilder/recurrency/" + e.event.extendedProps.formScheduleRecurrencyId + "/" + e.event.extendedProps.scheduleDate + "/" + e.event.extendedProps.scheduleSequence
                        : { pathname: "/formbuilder/apply/" + e.event.extendedProps.formId + "/" + e.event.id, state: { redirectURL: "/home" } })
              }}
              cancelText={documentControl ? documentControl2 ? t('BtnDelete') : t('BtnClose') : actionEdit != null && status != "Done" && (formTask || isRecurrency) ? t('BtnEdit') : t('BtnClose')}
              onCancel={() => {
                setFormTaskSelected(null);
                if (documentControl2) {
                  revisionDeclined(e.event.extendedProps.documentId, e.event.id);
                } else if (!documentControl && actionEdit != null && status != "Done") {
                  setFormTaskSelected(() => { return e.event });
                  setShowModalForm(true);
                }
              }}
            >
              <div>
                <div className={"event-text " + (status == "Open" ? "fc-open" : status == "Approval" ? "fc-approval" : status == "Done" ? "fc-done" : status == "Late" ? "fc-late" : status == "Pending" ? "fc-pending" : status == "Recurrency" ? "fc-recurrency" : "")}>
                  <small>
                    &nbsp;<ClockCircleOutlined />&nbsp; {moment(e.event.extendedProps.scheduleDate).format("HH:mm")}
                  </small>
                  <small style={{ float: "right", marginTop: 3 }}>
                    {hasFriendlyId && !isRecurrency && <>{t('Code')}: {e.event.extendedProps.friendlyId}</>}&nbsp;
                  </small>
                  <br></br>
                  {isRecurrency && <strong>&nbsp;<ReloadOutlined /></strong>}
                  &nbsp;
                  {truncateString(e.event.title, 50)}
                </div>
              </div>
            </Popconfirm>
          }}
          dateClick={(e) => {
            if (can(FEATURE.SCHEDULE, [ACTION.CREATE])) {
              setFormTaskSelected(null);
              setDateSelected(e.date);
              setShowModalForm(true);
            }
          }}
          customButtons={{
            new: {
              text: t('BtnNew'),
              click: async function () {
                setFormTaskSelected(null);
                setDateSelected(undefined);
                setShowModalForm(true);
              }
            },
            prev: {
              text: t('Schedule.Prev'),
              click: async function () {
                let calendarApi = calendarRef.current.getApi();
                calendarApi.prev();
                updateSchedule(moment(filterTaskScheduleRequest.date).add(-1, 'months').startOf('month'));
              }
            },
            next: {
              text: t('Schedule.Next'),
              click: async function () {
                let calendarApi = calendarRef.current.getApi();
                calendarApi.next();
                updateSchedule(moment(filterTaskScheduleRequest.date).add(1, 'months').startOf('month'));
              }
            },
            myTasks: {
              text: t('AsyncTask.Filters'),
              click: async function () {
                setShowModalFilter(true);
              }
            },
          }}
        />
        < Row gutter={[8, 8]} className="footer-container">
          <Col flex={"1 50%"}>
            <Tag color="#2b579a">{t('Pending')}</Tag>
            <Tag color="#ff4d4f">{t('Late')}</Tag>
            <Tag color="#4085c6">{t('Open')}</Tag>
            <Tag color="rgb(199, 200, 202)">{t('Approval')}</Tag>
            <Tag color="rgb(103, 209, 129)">{t('Done')}</Tag>
            <Tag color="#2c84c0">{t('Recurrency')}</Tag>
          </Col>
        </Row>
      </FullCalendarContainer>
    </Card>
  }

  function revisionDeclined(id, idTask) {
    Api.post(`/DocumentControl/revisionDeclined/${id}/${idTask}`, null)
      .then(response => {
        updateSchedule(moment(filterTaskScheduleRequest.date).startOf('month'));
      })
      .catch(error => {
        exceptionNotificationAPI(error);
      });
  }

  function updateSchedule(month) {
    let tempFilterRequest = {
      ...filterTaskScheduleRequest,
      pageNumber: 1,
      date: moment(month).add(1, 'days'),
    };
    setFilterTaskScheduleRequest(tempFilterRequest);
  }

  function adminSchedule(reportToHistory) {
    setFilterTaskScheduleRequest({
      ...filterTaskScheduleRequest,
      reportToHistory: reportToHistory,
    });
  }

  function locationSchedule(locationId) {
    setFilterTaskScheduleRequest({
      ...filterTaskScheduleRequest,
      locationId: locationId
    });
  }

  function topicSchedule(topicId) {
    setFilterTaskScheduleRequest({
      ...filterTaskScheduleRequest,
      topicId: topicId
    });
  }

  function locationBelowSchedule(include) {
    setFilterTaskScheduleRequest({
      ...filterTaskScheduleRequest,
      locationIncludeChildren: include
    });
  }

  function handleChangeTab(tabKey) {
    sessionStorage.removeItem("homeFilters");
    if (tabKey == 1) {
      //schedule calendar 
      setFilterStatusSchedule(getScheduleDropdownStatusOptions());
      adminSchedule(false);
      setFilterFavoriteRequest({ ...filterFavoriteRequest });
    } else if (tabKey == 2) {
      //history
      const historyInitialState = getHistoryInitialState(filters);
      if (filters == null || JSON.stringify(historyInitialState) != JSON.stringify(filterAnswersRequest)) {
        if (historyTextSearchRef && historyTextSearchRef.current)
          historyTextSearchRef.current.value = historyInitialState.searchText;
        setFilterAnswersRequest(historyInitialState);
      }
    } else if (tabKey == 4) {
      //adhoc forms / myActivities
      setFilterFormRequestLocationId(undefined);
      const adhocsInitialState = getFormADHOCInitialState();
      if (formAdhocSearchRef && formAdhocSearchRef.current)
        formAdhocSearchRef.current.value = adhocsInitialState.searchText;
      setFilterFormRequest(adhocsInitialState);
    }

    setTab(tabKey);
  }

  useEffect(() => {
    setSearchForList([]);
    if (filterNameSchedule != "") {
      filterTaskScheduleRequest.searchText = filterNameSchedule;
      Api.post('/Task/scheduleSearchFor', filterTaskScheduleRequest)
        .then(response => {
          setSearchForList(response.data.response);
        })
        .catch(error => {
          exceptionNotificationAPI(error);
        });
    }
  }, [filterNameSchedule, filterTaskScheduleRequest])

  return (
    <Container>
      <ContentPanelIndex>
        {showInfosScreen ? (
          <Tabs defaultActiveKey={tab} onChange={(a) => { handleChangeTab(a) }} className="font-face-gbold">
            {can(FEATURE.SCHEDULE, [ACTION.VIEW]) &&
              <TabPane tab={t('Home.TabTitle.Schedule')} key="1" className="pt-6 font-face-gbook">
                <Space className="header-formbuilder-list">
                </Space>
                <Row gutter={[16, 16]}>
                  <Col lg={16} sm={24}>
                    {loadingTableTaskSchedule && <Loading top={0} />}
                    {renderSchedule()}
                  </Col>
                  <Col lg={8} sm={24}>
                    <Row gutter={[16, 16]}>
                      <Col lg={24} sm={24}>
                        <Card>
                          <Title level={5} type="secondary">
                            {t('Home.Schedule.Favorites')}
                          </Title>
                          <Divider />
                          {loadingTableFavorite && <Loading style={{ marginTop: 6 }} />}
                          <div style={{ maxHeight: 340 }}>
                            {!loadingTableFavorite && renderFavoriteTable()}
                          </div>
                        </Card>
                      </Col>
                      <Col lg={24} sm={24}>
                        <Card>
                          <Title level={5} type="secondary">
                            {t('Home.WaitingApproval')}
                          </Title>
                          <Divider />
                          {loadingTableApprovalTask && <Loading style={{ marginTop: 6 }} />}
                          <div style={{ maxHeight: 340 }}>
                            {!loadingTableApprovalTask && renderApprovalTable()}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            }
            <TabPane tab={t('Home.TabTitle.History')} key="2" className="font-face-gbook">
              {filterAnswersRequest && <>
                <Row gutter={[8, 8]}>
                  <Col lg={24} sm={24}>
                    <Button
                      type="primary"
                      style={{ position: 'absolute', top: -70, right: 0 }}
                      onClick={() => setShowExportModal(true)}
                      className="btn-show-modal font-face-gbook"
                      icon={<ExportOutlined />}
                    >
                      {t('ExportData')}
                    </Button>
                  </Col>
                  <Col lg={3} sm={3}>
                    <Search
                      title="Search by FormName, UserReplied,Form Number, Code or CorrelationID"
                      placeholder={t("BtnSearch")}
                      allowClear
                      ref={historyTextSearchRef}
                      defaultValue={filterAnswersRequest?.searchText}
                      loading={loadingIconAnswersFilter}
                      onSearch={handleAnswersSearchFilter}
                      className="space-search"
                      disabled={loadingTableAnswers}
                    />
                  </Col>
                  <Col lg={3} sm={3}>
                    <UserPicker
                      placeholder={t("AssignedTo")}
                      className="space-search"
                      onChange={handleAnswersSearchUser}
                      value={filterAnswersRequest?.userId}
                      disabled={loadingTableAnswers}
                    />
                  </Col>
                  <Col lg={3} sm={3}>
                    <UserPicker
                      placeholder={t("CreatedBy")}
                      className="space-search"
                      onChange={handleAnswersSearchCreatedUser}
                      value={filterAnswersRequest?.createdBy}
                      disabled={loadingTableAnswers}
                    />
                  </Col>
                  <Col lg={3} sm={3}>
                    {renderAnswersTopicDropdown()}
                  </Col>
                  <Col lg={3} sm={3}>
                    {renderAnswersStatusDropdown()}
                  </Col>
                  <Col lg={3} sm={3}>
                    {renderAnswersReportDropdown()}
                  </Col>
                  <Col lg={3} sm={3}>
                    {renderAnswersLocationDropdown()}
                    {renderAnswersLocationBelowDropdown()}
                  </Col>
                  <Col lg={3} sm={3}>
                    {renderAnswersFormDropdown()}
                  </Col>
                </Row>
              </>}
              <div style={{ marginBottom: 10 }}></div>
              {loadingTableAnswers && <Loading />}
              {/* {!loadingTableAnswers && renderAnswerHeaderTable()} */}
              {!loadingTableAnswers && renderAnswersTable()}
            </TabPane>
            <TabPane tab={t('Home.TabTitle.AdhocMyActivities')} key="4" className="font-face-gbook">
              <Row gutter={[16, 16]}>
                <Col lg={24} sm={24}>
                  <Row gutter={[8, 8]}>
                    <Col lg={3} sm={3}>
                      <Search
                        placeholder={t("Name")}
                        allowClear
                        loading={loadingIconFormFilter}
                        ref={formAdhocSearchRef}
                        defaultValue={filterFormRequest.searchText}
                        onSearch={handleFormSearchFilter}
                        className="space-search"
                      />
                    </Col>
                    <Col lg={3} sm={3}>
                      {renderFormLocationDropdown()}
                    </Col>
                    <Col lg={3} sm={3}>
                      {renderFormTopicDropdown()}
                    </Col>
                  </Row>
                  <div style={{ marginBottom: 10 }}></div>
                  {loadingTableForm && <Loading />}
                  {!loadingTableForm && renderAdhocFormTable()}
                </Col>
              </Row>
            </TabPane>
            {can(FEATURE.ACCESS, [ACTION.WEBACCESS]) && <TabPane tab={"Workflow"} key="5" className="font-face-gbook">
              {tab == 5 ? <WorkflowTab /> : null}
            </TabPane>}
          </Tabs>
        ) : (
          <Loading />
        )}

        {
          showModalFilter && <Modal
            title={"Filter Schedule"}
            style={{ zIndex: '999999 !important' }}
            className="font-face-gbold"
            open={showModalFilter}
            onCancel={() => setShowModalFilter(false)}
            footer=""
            width={750}
          >
            <Divider />

            <Row gutter={[8, 8]} className="font-face-gbook">
              <Col span={12}>
                <strong>{t('History.Tasks')}</strong>
                <Select
                  style={{ width: '100%', marginTop: 5 }}
                  value={filterTaskScheduleRequest.reportToHistory}
                  className="space-search"
                  onChange={(e) => adminSchedule(e)}
                >
                  <Option value={1}>{t('History.MyTasks')}</Option>
                  {user?.showOnlyMyTasks !== true ? <Option value={2}>{t('History.LocationTasks')}</Option> : null}
                  {user?.showOnlyMyTasks !== true ? <Option value={3}>{t('History.ReportTasks')}</Option> : null}
                </Select>
              </Col>
              <Col span={12}>
                <strong style={{ marginBottom: 5 }}>{t('Topic')}</strong>
                <br></br>
                <TreeSelectTopic
                  style={{ width: '100%', marginTop: 5 }}
                  className="space-search"
                  onChange={topicSchedule}
                  value={filterTaskScheduleRequest.topicId}
                ></TreeSelectTopic>
              </Col>
            </Row>

            <Row gutter={[8, 8]} style={{ marginTop: 20 }} className="font-face-gbook">
              <Col span={24}>
                <strong style={{ marginBottom: 5 }}>{t('Status.Task')}</strong>
                {renderScheduleStatusDropdown()}
              </Col>
            </Row>

            <Row gutter={[8, 8]} style={{ marginTop: 20 }} className="font-face-gbook">
              <Col span={24}>
                <strong style={{ marginBottom: 5 }}>{t('DCSearchFor')}</strong>
                <Search
                  allowClear={true}
                  defaultValue={filterNameSchedule}
                  style={{ width: '100%', height: 40, marginTop: 5 }}
                  onSearch={(e) => {
                    setFilterNameSchedule(e);
                    setFilterTaskScheduleRequest({
                      ...filterTaskScheduleRequest,
                      searchText: e
                    });
                  }}
                  className="space-search"
                />
                {searchForList.map((s) => {
                  return <Tag
                    color="#2b579a"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      let calendarApi = calendarRef.current.getApi();
                      calendarApi.gotoDate(moment(s).format());
                      updateSchedule(calendarApi.getDate())
                    }}>
                    {moment(s).format("MM / YYYY")}
                  </Tag>
                })}
              </Col>
            </Row>

            <Row gutter={[8, 8]} style={{ marginTop: 20 }} className="font-face-gbook">
              <Col span={12}>
                <strong style={{ marginBottom: 5 }}>{t('Location')}</strong>
                <TreeSelectLocationUser
                  style={{ width: '100%', marginTop: 5 }}
                  includeDirectParents={false}
                  className="space-search"
                  value={filterTaskScheduleRequest.locationId}
                  onChange={(e) => locationSchedule(e)}
                ></TreeSelectLocationUser>
              </Col>
              <Col span={12}>
                <strong style={{ marginBottom: 5 }}>{t('History.LocationBelow')}</strong>
                <br></br>
                <Switch style={{ marginTop: 15 }} checked={filterTaskScheduleRequest.locationIncludeChildren} onChange={locationBelowSchedule} />
              </Col>
            </Row>
          </Modal>
        }

        <Modal
          title={formTaskSelected != null && formTaskSelected.id ? t("BtnEdit") : t("BtnNew")}
          className="font-face-gbold"
          open={showModalForm}
          onCancel={() => setShowModalForm(false)}
          footer=""
          width={750}
        >
          <ScheduleForm
            formTaskSelected={formTaskSelected}
            dateSelected={dateSelected}
            setShowModalForm={setShowModalForm}
            getTaskScheduleListData={getTaskScheduleListData}
          />
        </Modal>

        <Modal
          title={t('ExportData')}
          className="font-face-gbold"
          open={showExportModal}
          onCancel={() => setShowExportModal(false)}
          footer=""
          width={'60vw'}
        >
          <ExportForm
            closeModal={setShowExportModal}
            statusAnswersList={statusAnswersList}
            baseUserLocation={user?.locationId}
          />
        </Modal>
      </ContentPanelIndex >
    </Container >
  );
};

export default HomeWrapper;