import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import 'antd/dist/reset.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeProvider } from "styled-components";
import WebFont from "webfontloader";
import { DirtyProvider } from "./components/Blocker/dirty";
import AppProvider from './hooks';
import RoutesList from './routes';
import DefaultStyle from './styles/global';
import Theme from './styles/theme';
import '@xyflow/react/dist/style.css';
WebFont.load({ google: { families: ["Nunito:100,200,300,400,500,600,700,800,900"] } });

Spin.setDefaultIndicator(<LoadingOutlined />);

function App() {
  return (
    <DirtyProvider>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={Theme}>
          <AppProvider>
            <RoutesList forceRefresh={true} />
          </AppProvider>
          <DefaultStyle />
        </ThemeProvider>
      </DndProvider>
    </DirtyProvider>
  );
}

export default App;
