import { ArrowLeftOutlined, CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, DownloadOutlined, ExclamationCircleOutlined, InfoCircleOutlined, OrderedListOutlined } from "@ant-design/icons";
import { Button, Card, Col, Progress, Row, Select, Spin, Tag, Timeline, Tooltip, Typography, } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaList } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, } from "react-virtualized";
import { TreeSelectLocationUser } from "../../components/TreeSelectLocation";
import Api, { Api_v2, exceptionNotificationAPI } from "../../services/api";
import { TASK_STATUS } from "../FormBuilder/const";

const { Title, Text } = Typography;
const { Option } = Select;

const CORRELATION_STATUS = {
    0: "Idle",
    1: "Executing",
    2: "Finished",
    3: "Faulted",
    4: "Aborted",
};

const WorkflowTab = () => {
    const { t } = useTranslation();

    const [workflows, setWorkflows] = useState(null);
    const [definitionId, setDefinitionId] = useState(null);
    const [correlations, setCorrelations] = useState(null);
    const [correlationId, setCorrelationId] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [loading, setLoading] = useState(false);

    const [locationId, setLocationId] = useState(null);
    const [correlationStatusFilter, setCorrelationStatusFilter] = useState(null);

    const workflowCache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
    });
    const correlationCache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
    });

    const [statusCounts, setStatusCounts] = useState({
        done: 0,
        late: 0,
        open: 0,
        pending: 0,
    });

    const [corrStatusCounts, setCorrStatusCounts] = useState({
        idle: 0,
        executing: 0,
        finished: 0,
        faulted: 0,
        aborted: 0,
    });

    useEffect(() => {
        fetchWorkflows();
    }, []);

    useEffect(() => {
        if (definitionId) {
            fetchCorrelations(definitionId, locationId);
        }
    }, [locationId]);

    useEffect(() => {
        if (tasks && tasks.length > 0) {
            const done = tasks.filter((t) => t.status === "Done").length;
            const late = tasks.filter((t) => t.status === "Late").length;
            const open = tasks.filter((t) => t.status === "Open").length;
            const pending = tasks.filter((t) => t.status === "Pending").length;
            setStatusCounts({ done, late, open, pending });
        } else {
            setStatusCounts({ done: 0, late: 0, open: 0, pending: 0 });
        }
    }, [tasks]);

    useEffect(() => {
        if (correlations && correlations.length > 0) {
            const idle = correlations.filter((c) => c.status === 0).length;
            const executing = correlations.filter((c) => c.status === 1).length;
            const finished = correlations.filter((c) => c.status === 2).length;
            const faulted = correlations.filter((c) => c.status === 3).length;
            const aborted = correlations.filter((c) => c.status === 4).length;
            setCorrStatusCounts({ idle, executing, finished, faulted, aborted });
        } else {
            setCorrStatusCounts({
                idle: 0,
                executing: 0,
                finished: 0,
                faulted: 0,
                aborted: 0,
            });
        }
    }, [correlations]);

    const fetchWorkflows = async () => {
        setLoading(true);
        try {
            const response = await Api.post("Task/getLatestPublishedWorkflows");
            setWorkflows(response.data.response);
            resetState();
        } catch (error) {
            exceptionNotificationAPI(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCorrelations = async (definitionId, locationId = null) => {
        setLoading(true);
        try {
            const endpoint =
                "Task/getLatestPublishedWorkflowsInstances/" +
                definitionId +
                (locationId ? "/" + locationId : "");
            const response = await Api.post(endpoint);
            setCorrelations(response.data.response);
            setDefinitionId(definitionId);
            setTasks(null); // Reseta as tarefas
            correlationCache.clearAll();
        } catch (error) {
            exceptionNotificationAPI(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTasks = async (corrId) => {
        setLoading(true);
        setCorrelationId(null);
        try {
            const response = await Api.post(`Task/getTasksByCorrelationId/${corrId}`);
            setCorrelationId(corrId);
            setTasks(response.data.response);
        } catch (error) {
            exceptionNotificationAPI(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async (corrId) => {
        setLoading(true);
        try {
            const response = await Api_v2.Workflow.PdfReport(corrId);
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
            );
            const date = new Date().toISOString().split("T")[0].replace(/-/g, "");
            const filename = `Workflow_${corrId}_Report_${date}.pdf`;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erro ao baixar o PDF", error);
        } finally {
            setLoading(false);
        }
    };

    const resetState = () => {
        setDefinitionId(null);
        setCorrelations(null);
        setTasks(null);
    };

    const goBack = () => {
        if (tasks) {
            setTasks(null);
        } else if (correlations) {
            setCorrelations(null);
            setDefinitionId(null);
        }
    };

    const filteredCorrelations = useMemo(() => {
        if (!correlations) return [];
        if (correlationStatusFilter == null) return correlations;
        const statusNumber = parseInt(correlationStatusFilter, 10);
        return correlations.filter((c) => c.status === statusNumber);
    }, [correlations, correlationStatusFilter]);

    const getCorrelationStatusTag = (status) => {
        let color = "default";
        switch (status) {
            case 0:
                color = "rgb(199, 200, 202)"; // Idle
                break;
            case 1:
                color = "#4085c6"; // Executing
                break;
            case 2:
                color = "rgb(103, 209, 129)"; // Finished
                break;
            case 3:
            case 4:
                color = "#ff4d4f"; // Faulted / Aborted
                break;
            default:
                color = "default";
        }
        return <Tag color={color}>{t(CORRELATION_STATUS[status])}</Tag>;
    };

    const getTaskStatusTag = (status) => {
        const originalStatus = status;
        status = TASK_STATUS[status];
        let color = "default";
        if (status === TASK_STATUS.Pending) {
            color = "rgb(199, 200, 202)";
        } else if (status === TASK_STATUS.Open) {
            color = "#4085c6";
        } else if (status === TASK_STATUS.Done) {
            color = "rgb(103, 209, 129)";
        } else if (status === TASK_STATUS.Late) {
            color = "#ff4d4f";
        }
        return <Tag color={color}>{t(originalStatus)}</Tag>;
    };

    const groupTasksByParent = (tasksList) => {
        return tasksList.reduce((acc, task) => {
            if (task.taskTypeId === "df3cb0ad-9e0a-44e1-898c-3e3a5df1483e") {
                if (!acc[task.taskId]) {
                    acc[task.taskId] = { parent: null, approvals: [] };
                }
                acc[task.taskId].approvals.push(task);
            } else {
                acc[task.id] = acc[task.id] || { parent: null, approvals: [] };
                acc[task.id].parent = task;
            }
            return acc;
        }, {});
    };

    const getCorrelationPercent = (count) => {
        if (!correlations || correlations.length === 0) return 0;
        return Math.round((count / correlations.length) * 100);
    };

    const getPercent = (count) => {
        if (!tasks || tasks.length === 0) return 0;
        return Math.round((count / tasks.length) * 100);
    };

    const renderWorkflowRow = ({ index, key, style, parent }) => {
        const workflow = workflows[index];
        return (
            <CellMeasurer
                key={key}
                cache={workflowCache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                {({ registerChild }) => (
                    <div style={style} ref={registerChild}>
                        <Card
                            className="new-card-history card-p-10"
                            style={{ cursor: "pointer", marginBottom: "5px" }}
                            onClick={() => {
                                workflowCache.clearAll();
                                fetchCorrelations(workflow.definitionId);
                            }}
                        >
                            <Row>
                                <Col span={12}>
                                    <Text>{t("Workflow Name")}: </Text>
                                    <br />
                                    <strong style={{ color: "#000" }}>{workflow.name}</strong>
                                </Col>
                                <Col span={12}>
                                    <Text>{t("Version")}: </Text>
                                    <br />
                                    <strong style={{ color: "#000" }}>{workflow.version}</strong>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const renderCorrelationRow = ({ index, key, style, parent }) => {
        const correlation = filteredCorrelations[index];
        return (
            <CellMeasurer
                key={key}
                cache={correlationCache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                {({ registerChild }) => (
                    <div style={style} ref={registerChild}>
                        <Card
                            className="new-card-history card-p-10"
                            style={{ cursor: "pointer", marginBottom: "5px" }}
                            onClick={() => {
                                correlationCache.clearAll();
                                fetchTasks(correlation.correlationId);
                            }}
                        >
                            <Row>
                                <Col span={2}>
                                    <Text>{t("Correlation ID")}: </Text>
                                    <br />
                                    <strong style={{ color: "#000" }}>
                                        {correlation.correlationId}
                                    </strong>
                                </Col>
                                <Col span={2}>
                                    <Text>{t("Status")}: </Text>
                                    <br />
                                    {getCorrelationStatusTag(correlation.status)}
                                </Col>
                                <Col span={5}>
                                    <Text>{t("Last Task Description")}: </Text>
                                    <br />
                                    <strong style={{ color: "#000" }}>
                                        {correlation.lastRunningTaskName}
                                    </strong>
                                </Col>
                                <Col span={5}>
                                    <Text>{t("Task Location Name")}: </Text>
                                    <br />
                                    <strong style={{ color: "#000" }}>
                                        {correlation.lastRunningLocationName}
                                    </strong>
                                </Col>
                                <Col span={6}>
                                    <Text>{t("Last Form Name")}: </Text>
                                    <br />
                                    <strong style={{ color: "#000" }}>
                                        {correlation.lastRunningFormName}
                                    </strong>
                                </Col>
                                <Col span={4}>
                                    <Text>{t("Last Task Assigned To")}: </Text>
                                    <br />
                                    <strong style={{ color: "#000" }}>
                                        {correlation.lastRunningTaskUserName}
                                    </strong>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                )}
            </CellMeasurer>
        );
    };

    return (
        <Spin spinning={loading} size="large">
            <div>
                {correlations && !tasks && (
                    <Row gutter={[8, 8]} style={{ marginBottom: 10 }}>
                        <Col span={4}>
                            <Button
                                type="primary"
                                onClick={goBack}
                                icon={<ArrowLeftOutlined />}
                            >
                                {t("Back to Workflows")}
                            </Button>
                        </Col>
                        <Col span={4}>
                            <TreeSelectLocationUser
                                placeholder={t("Location")}
                                includeDirectParents={false}
                                className="space-search"
                                value={locationId}
                                onChange={(e) => setLocationId(e)}
                            />
                        </Col>
                        <Col span={4}>
                            <Select
                                allowClear
                                placeholder={t("Status")}
                                style={{ width: "100%" }}
                                onChange={(value) => setCorrelationStatusFilter(value)}
                            >
                                <Option value="0">{t("Idle")}</Option>
                                <Option value="1">{t("Executing")}</Option>
                                <Option value="2">{t("Finished")}</Option>
                                <Option value="3">{t("Faulted")}</Option>
                                <Option value="4">{t("Aborted")}</Option>
                            </Select>
                        </Col>
                    </Row>
                )}

                {correlations && !tasks && (
                    <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>Executing</Text>
                                    <InfoCircleOutlined style={{ color: "#4085c6", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{corrStatusCounts.executing}</Title>
                                <Progress
                                    percent={getCorrelationPercent(corrStatusCounts.executing)}
                                    strokeColor="#4085c6"
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>Finished</Text>
                                    <CheckCircleOutlined style={{ color: "#67D181", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{corrStatusCounts.finished}</Title>
                                <Progress
                                    percent={getCorrelationPercent(corrStatusCounts.finished)}
                                    strokeColor="#67D181"
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>Faulted</Text>
                                    <ExclamationCircleOutlined style={{ color: "#ff4d4f", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{corrStatusCounts.faulted}</Title>
                                <Progress
                                    percent={getCorrelationPercent(corrStatusCounts.faulted)}
                                    strokeColor="#ff4d4f"
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>Aborted</Text>
                                    <CloseCircleOutlined style={{ color: "#ff4d4f", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{corrStatusCounts.aborted}</Title>
                                <Progress
                                    percent={getCorrelationPercent(corrStatusCounts.aborted)}
                                    strokeColor="#ff4d4f"
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>Total</Text>
                                    <OrderedListOutlined style={{ color: "#999999", fontSize: 20 }} />
                                </Row>
                                <Title level={2} style={{ marginBottom: 37 }}>{corrStatusCounts.idle + corrStatusCounts.executing + corrStatusCounts.faulted + corrStatusCounts.finished + corrStatusCounts.aborted}</Title>
                            </Card>
                        </Col>
                    </Row>
                )}

                {tasks && (
                    <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
                        <Col span={4}>
                            <Button
                                type="primary"
                                onClick={goBack}
                                icon={<ArrowLeftOutlined />}
                            >
                                {t("Back to Correlations")}
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button
                                type="primary"
                                onClick={() => handleDownload(correlationId)}
                                icon={<DownloadOutlined />}
                            >
                                {t("Report")}
                            </Button>
                        </Col>
                    </Row>
                )}

                {tasks && tasks.length > 0 && (
                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>{t('Done')}</Text>
                                    <CheckCircleOutlined style={{ color: "#67D181", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{statusCounts.done}</Title>
                                <Progress
                                    percent={getPercent(statusCounts.done)}
                                    strokeColor="#67D181"
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>{t('Late')}</Text>
                                    <ExclamationCircleOutlined style={{ color: "#FF4D4F", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{statusCounts.late}</Title>
                                <Progress
                                    percent={getPercent(statusCounts.late)}
                                    strokeColor="#FF4D4F"
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>{t('Pending')}</Text>
                                    <ClockCircleOutlined style={{ color: "rgb(199, 200, 202)", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{statusCounts.pending}</Title>
                                <Progress
                                    percent={getPercent(statusCounts.pending)}
                                    strokeColor="rgb(199, 200, 202)"
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <Text>{t('Open')}</Text>
                                    <ClockCircleOutlined style={{ color: "#FAAD14", fontSize: 20 }} />
                                </Row>
                                <Title level={2}>{statusCounts.open}</Title>
                                <Progress
                                    percent={getPercent(statusCounts.open)}
                                    strokeColor="#FAAD14"
                                />
                            </Card>
                        </Col>
                    </Row>
                )}

                {tasks && (
                    <>
                        <Timeline>
                            {Object.values(groupTasksByParent(tasks)).map((group, index) => (
                                <Timeline.Item
                                    key={index}
                                    dot={<FaList style={{ color: "#1890ff", fontSize: 16 }} />}
                                    color="blue"
                                    style={{ paddingBottom: 10 }}
                                >
                                    {group.parent && (
                                        <Card
                                            className="new-card-history card-p-10"
                                            style={{ cursor: "pointer" }}
                                        >
                                            {group.parent.taskTypeId ===
                                                "df3cb0ad-9e0a-44e1-898c-3e3a5df1483e" ? (
                                                <div>
                                                    <Row>
                                                        <Col span={3}>
                                                            <Text>{t("TaskChangeDate")}: </Text>
                                                            <br />
                                                            <strong style={{ color: "#000" }}>
                                                                {moment(group.parent.insertDate).format(
                                                                    "YYYY-MMM-DD HH:mm"
                                                                )}
                                                            </strong>
                                                        </Col>
                                                        <Col span={6} />
                                                        <Col span={6} />
                                                        <Col span={4}>
                                                            <Text>{t("TaskApproved")}: </Text>
                                                            <br />
                                                            <strong style={{ color: "#000" }}>
                                                                {group.parent.userName}
                                                            </strong>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Text>{t("Status")}: </Text>
                                                            <br />
                                                            {getTaskStatusTag(group.parent.status)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : (
                                                <Link
                                                    target="_blank"
                                                    to={`/formbuilder/view-answer/${group.parent.id}`}
                                                >
                                                    <Tooltip title={t("Click to view the task")}>
                                                        <Row>
                                                            <Col span={3}>
                                                                <Text>{t("TaskChangeDate")}: </Text>
                                                                <br />
                                                                <strong style={{ color: "#000" }}>
                                                                    {moment(group.parent.insertDate).format(
                                                                        "YYYY-MMM-DD HH:mm"
                                                                    )}
                                                                </strong>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Text>{t("Form Name")}: </Text>
                                                                <br />
                                                                <strong style={{ color: "#000" }}>
                                                                    {group.parent.name}
                                                                </strong>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Text>{t("Description")}: </Text>
                                                                <br />
                                                                <strong style={{ color: "#000" }}>
                                                                    {group.parent.description}
                                                                </strong>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Text>{t("AssignedTo")}: </Text>
                                                                <br />
                                                                <strong style={{ color: "#000" }}>
                                                                    {group.parent.userName}
                                                                </strong>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Text>{t("Status")}: </Text>
                                                                <br />
                                                                {getTaskStatusTag(group.parent.status)}
                                                            </Col>
                                                        </Row>
                                                    </Tooltip>
                                                </Link>
                                            )}
                                        </Card>
                                    )}
                                    {group.approvals.length > 0 && (
                                        <Timeline style={{ marginTop: 10 }}>
                                            {group.approvals.map((approval, approvalIndex) => (
                                                <Timeline.Item
                                                    key={approvalIndex}
                                                    color={approval.status === "Done" ? "green" : "blue"}
                                                    dot={<FaList style={{ color: "#1890ff", fontSize: 16 }} />}
                                                    style={{ paddingBottom: 10 }}
                                                >
                                                    <Card
                                                        className="new-card-history card-p-10"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {approval.taskTypeId ===
                                                            "df3cb0ad-9e0a-44e1-898c-3e3a5df1483e" ? (
                                                            <div>
                                                                <Row>
                                                                    <Col span={3}>
                                                                        <Text>{t("TaskChangeDate")}: </Text>
                                                                        <br />
                                                                        <strong style={{ color: "#000" }}>
                                                                            {moment(approval.insertDate).format(
                                                                                "YYYY-MMM-DD HH:mm"
                                                                            )}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <Text>{t("TaskApproved")}: </Text>
                                                                        <br />
                                                                        <strong style={{ color: "#000" }}>
                                                                            {approval.userName}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <Text>{t("Status")}: </Text>
                                                                        <br />
                                                                        {getTaskStatusTag(approval.status)}
                                                                    </Col>
                                                                    <Col span={6} />
                                                                    <Col span={6} />
                                                                </Row>
                                                            </div>
                                                        ) : (
                                                            <Link
                                                                target="_blank"
                                                                to={`/formbuilder/view-answer/${approval.id}`}
                                                            >
                                                                <Tooltip title={t("Click to view the approval task")}>
                                                                    <Row>
                                                                        <Col span={3}>
                                                                            <Text>{t("TaskChangeDate")}: </Text>
                                                                            <br />
                                                                            <strong style={{ color: "#000" }}>
                                                                                {moment(approval.insertDate).format(
                                                                                    "YYYY-MMM-DD HH:mm"
                                                                                )}
                                                                            </strong>
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Text>{t("Form Name")}: </Text>
                                                                            <br />
                                                                            <strong style={{ color: "#000" }}>
                                                                                {approval.name}
                                                                            </strong>
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Text>{t("Description")}: </Text>
                                                                            <br />
                                                                            <strong style={{ color: "#000" }}>
                                                                                {approval.description}
                                                                            </strong>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Text>{t("AssignedTo")}: </Text>
                                                                            <br />
                                                                            <strong style={{ color: "#000" }}>
                                                                                {approval.userName}
                                                                            </strong>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Text>{t("Status")}: </Text>
                                                                            <br />
                                                                            {getTaskStatusTag(approval.status)}
                                                                        </Col>
                                                                    </Row>
                                                                </Tooltip>
                                                            </Link>
                                                        )}
                                                    </Card>
                                                </Timeline.Item>
                                            ))}
                                        </Timeline>
                                    )}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </>
                )}

                {/* Lista de correlações (fluxos) */}
                {correlations && !tasks && (
                    <div style={{ height: "60vh", marginTop: 10 }}>
                        <AutoSizer>
                            {({ width, height }) => (
                                <List
                                    width={width}
                                    height={height}
                                    rowCount={filteredCorrelations.length}
                                    rowHeight={correlationCache.rowHeight}
                                    rowRenderer={renderCorrelationRow}
                                    deferredMeasurementCache={correlationCache}
                                />
                            )}
                        </AutoSizer>
                    </div>
                )}

                {/* Lista de workflows (página inicial) */}
                {workflows && !correlations && !tasks && (
                    <div style={{ height: "80vh" }}>
                        <AutoSizer>
                            {({ width, height }) => (
                                <List
                                    width={width}
                                    height={height}
                                    rowCount={workflows.length}
                                    rowHeight={workflowCache.rowHeight}
                                    rowRenderer={renderWorkflowRow}
                                    deferredMeasurementCache={workflowCache}
                                />
                            )}
                        </AutoSizer>
                    </div>
                )}
            </div>
        </Spin>
    );
};

export default WorkflowTab;
